<template>
    <div :class="getClass()" class="padding flix-text-center flix-html-h2">
      <flixIcon :id="$getStatusIcon({status: status})" /> {{ $t("message."+status) }}: {{ current }} x
    </div>
</template>
<script>
export default {
  props: { entries: Array },
  data () {
    return {
      current: this.entries.length,
      status: 'commitment'
    }
  },
  methods: {
    getClass () {
      var r = {}
      r['flix-bg-' + this.$getStatusClass(this.status)] = true
      r['flix-text-' + this.$getStatusClass(this.status)] = true
      return r
    }
  }
}
</script>
<style lang="sass" scoped>
  .padding
    padding: 20px
    margin: 0
</style>
